import * as CryptoJS from 'crypto-js';
import Axios from "axios";
import {SETTING} from '../app-config/cofiguration';
require('dotenv').config()

// const SECRET_MSG= process.env.REACT_APP_SECRET_MSG
const SECRET_MSG= process.env.REACT_APP_SECRET_MSG
const SECRET_MSG_PASSWORD = process.env.REACT_APP_SECRET_MSG_PASSWORD
const FIRBASE_STORAGE_PROJECT_ID= process.env.REACT_APP_FIRBASE_STORAGE_PROJECT_ID
//export const imageBaseUrl=`https://bmmschool.in/uploads/`
export const imageBaseUrl=`https://storage.googleapis.com/${FIRBASE_STORAGE_PROJECT_ID}.appspot.com`

Date.prototype.getFinancialMonthIndex = function() {
  // Get the actual month index (0-11)
  const actualMonthIndex = this.getMonth();
  // Shift the index so that April is 1 and March is 12
  return (actualMonthIndex + 9) % 12 + 1;
};
export const isValidFile = (file) => {
  if (!file) return false;

  // List of supported MIME types
  const supportedMimeTypes = ['image/png', 'image/jpeg', 'image/jpg'];

  // Check the file type
  return supportedMimeTypes.includes(file.type);
};

const axios = require('axios');

// Function to be called
// async function callApi() {
//     try {
//         const response = await axios.get(`https://bmmsbackendapp.onrender.com/`);
//         console.log(response.data);
//     } catch (error) {
//         console.error('Error calling the API:', error);
//     }
// }

// Function to check the current time and call the API if within the time range
// function checkAndCallApi() {
//     const now = new Date();
//     const start = new Date();
//     start.setHours(7, 0, 0, 0); // 7 AM
//     const end = new Date();
//     end.setHours(19, 0, 0, 0); // 7 PM

//     if (now >= start && now <= end) {
//         callApi();
//     }
// }

// Set interval to call checkAndCallApi every 40 seconds
// setInterval(checkAndCallApi, 40000);

// // Initial call to start the process immediately
// checkAndCallApi();
// Initial call to start the process immediately
// checkAndCallApi();

export const  formatToIndianCurrency=(amount) =>{
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
  }).format(amount);
}
export const formatDate=(date)=> {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
let vehicleList=[]
let busRouteFareList=[]
let monthlyFeeList=[]
let payOptionList=[]
let allStudentUserIdList=[]
let allStudentPhoneList=[]


 export const getAllList=async(session)=>{
  // debugger
  // const cachedData = await localStorage.getItem('allListHelper');
  // console.log("cachedData111111111111111111", cachedData)
  //console.log("vehicleListvehicleListvehicleList", cachedData.vehicleList.length)
  if(true){
    let options = SETTING.HEADER_PARAMETERS;
    options['Authorization'] = localStorage.getItem("token")
    const url= new URL(SETTING.APP_CONSTANT.API_URL+`admin/getAllList`)
    if(session){
        url.searchParams.set('session', session)
    }
    const res =  await Axios.get(url,{headers: options})
    if (res && res.data.success) {
        const allListHelper={
          vehicleList: res.data.data.vehicleList,
          busRouteFareList: res.data.data.busRouteFareList,
          monthlyFeeList: res.data.data.monthlyFeeList,
          payOptionList : res.data.data.payOptionList,
          allStudentUserIdList:res.data.data.allStudentUserIdList,
          allStudentPhoneList: res.data.data.allStudentPhoneList,
          paymentRecieverUserList: res.data.data.paymentRecieverUserList
      }
      //const encryptList= CryptoJS.AES.encrypt(allListHelper, SECRET_MSG).toString()
      //localStorage.setItem('allListHelper', JSON.stringify("hello"));
      return allListHelper
    } 
  }else{
    // const allListHelper={
    //   vehicleList: cachedData.vehicleList,
    //   busRouteFareList: cachedData.busRouteFareList,
    //   monthlyFeeList: cachedData.monthlyFeeList,
    //   payOptionList : cachedData.payOptionList,
    //   allStudentUserIdList:cachedData.allStudentUserIdList,
    //   allStudentPhoneList: cachedData.allStudentPhoneList
    // }
    // return allListHelper
  }
 
 }

 export const sendDueMessage=async(numbers, userId, amount)=>{
  const payLoad={
      userId:  userId,
      toNumber: numbers,
      templateType: 'general',
      otherDetail:{
        amount: amount
      }
  }
  let url = `admin/sendMessage`

  let options = SETTING.HEADER_PARAMETERS;
  options['Authorization'] = localStorage.getItem("token");

try {
  const res = await Axios.post(SETTING.APP_CONSTANT.API_URL + url, payLoad, { headers: options });
  if (res && res.data.success) {
    return { status: true, message: res.data.message };
  } else {
    return { status: false, message: res.data.message };
  }
} catch (err) {
  if (err && err.response && err.response.status === 401) {
    return { status: false, message: 'Token expired.' };
  } else {
    const errorMessage = err && err.response && err.response.data && err.response.data.message 
      ? err.response.data.message 
      : 'Error while sending message.';
    return { status: false, message: errorMessage };
  }
}

}

export const sendPassword=async(numbers, userId)=>{
  const payLoad={
      userId:  userId,
      toNumber: numbers,
      templateType: 'sharePassword',
  }
  let url = `admin/sendMessage`

  let options = SETTING.HEADER_PARAMETERS;
  options['Authorization'] = localStorage.getItem("token");

try {
  const res = await Axios.post(SETTING.APP_CONSTANT.API_URL + url, payLoad, { headers: options });
  if (res && res.data.success) {
    return { status: true, message: res.data.message };
  } else {
    return { status: false, message: res.data.message };
  }
} catch (err) {
  if (err && err.response && err.response.status === 401) {
    return { status: false, message: 'Token expired.' };
  } else {
    const errorMessage = err && err.response && err.response.data && err.response.data.message 
      ? err.response.data.message 
      : 'Error while sending message.';
    return { status: false, message: errorMessage };
  }
}

}




export const  encryptAES = (text) => {

    return CryptoJS.AES.encrypt(text, SECRET_MSG).toString();
}

export const decryptAES = (encryptedBase64) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, SECRET_MSG);
    if (decrypted) {
        try {
            const str = decrypted.toString(CryptoJS.enc.Utf8);
            if (str.length > 0) {
                return str;
            } else {
                return encryptedBase64;
            }
        } catch (e) {
            return encryptedBase64;
        }
    }
    return encryptedBase64;
}
export const passwordDecryptAES = (encryptedBase64) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, SECRET_MSG_PASSWORD);
    if (decrypted) {
        try {
            const str = decrypted.toString(CryptoJS.enc.Utf8);
            if (str.length > 0) {
                return str;
            } else {
                return encryptedBase64;
            }
        } catch (e) {
            return encryptedBase64;
        }
    }
    return encryptedBase64;
  }

export const logoutFunc=()=>{
      localStorage.clear();
      setTimeout(()=>{
        window.location.href = "/user-pages/login-1"
     },100)
}
export const capitalize=(sentance)=>{
  // if(sentance.length===0) return ""

  // const words = sentance.split(" ");
  // const capitalizeWord = words.map((word) => { 
  //     return word[0].toUpperCase() + (word.substring(1) && word.substring(1).trim().length>0 && word.substring(1).trim().toLowerCase()); 
  // }).join(" ");
  // return capitalizeWord
  
  sentance = sentance.replace(/ +(?= )/g,'')
  if(sentance.length===0) return ""

  const words = sentance.split(" ");
  const capitalizeWord = words.map((word) => { 
    if(word.trim().length>0) return word[0].toUpperCase() + (word.substring(1)? word.substring(1).trim().length>0 ? word.substring(1).trim().toLowerCase():'':''); 
  }).join(" ");
  return capitalizeWord
  
}

export const currentSession =()=>{
    const currentDate= new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth= currentDate.getMonth()
    let session=''
    if(currentMonth>=3){
        session = `${(currentYear).toString()}-${(currentYear+1).toString().substring(2)}`
    }else{
        session = `${(currentYear-1).toString()}-${(currentYear).toString().substring(2)}`
    }
    //console.log("session", session)
    return session
}
export const previousSession = () => {
  const currentDate= new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth= currentDate.getMonth()
  let previousSession = '';

  if (currentMonth >=3) { 
      previousSession = `${(currentYear - 1).toString()}-${currentYear.toString().substring(2)}`;
  } else { 
      previousSession = `${(currentYear - 2).toString()}-${(currentYear - 1).toString().substring(2)}`;
  }
  //console.log("previousSession", previousSession)
  return previousSession
}

export const getAdmissionSession=(admmissionDate)=>{
  const currentDate= new Date(admmissionDate)
  const currentYear = currentDate.getFullYear()
  const currentMonth= currentDate.getMonth()
  let admissionSession=''
  if(currentMonth>=3){
    admissionSession = `${(currentYear).toString()}-${(currentYear+1).toString().substring(2)}`
  }else {
    admissionSession = `${(currentYear-1).toString()}-${(currentYear).toString().substring(2)}`
  }
  //console.log("admissionSession", admissionSession)
  return admissionSession
}

export const addExamFees = (feeData, isAnnualExamFeeNotPaid, isHalfExamFeeNotPaid) => {
  let dueAmt=0
  if (isAnnualExamFeeNotPaid) {
      dueAmt += feeData && feeData.annualExamFee ? Number(feeData.annualExamFee) : 0;
  }
  if (isHalfExamFeeNotPaid) {
      dueAmt += feeData && feeData.halfExamFee ? Number(feeData.halfExamFee) : 0;
  }
  return dueAmt;
};

export const getFinancialMonthIndex = (actualMonthIndex)=>{
  // Shifting the index so that April is 1 and March is 12
  return (actualMonthIndex + 9) % 12 + 1;
}

// Helper function to determine if the admission date falls within a specific session
export const isAdmissionInCurrentSession = (admissionSession, previousSession) => admissionSession === previousSession;

export function decryptAESObj(objecData){
    if (typeof objecData !== 'object' || objecData === null) {
        return objecData; // Base case: return if obj is not an object or is null
    }
    objecData.userInfo.roleName= decryptAES(objecData.userInfo.roleName)
    objecData.userInfo.roleId= decryptAES(objecData.userInfo.roleId)
    objecData.userInfo.phoneNumber1= decryptAES(objecData.userInfo.phoneNumber1)
    objecData.userInfo.phoneNumber2= decryptAES(objecData.userInfo.phoneNumber2)
    objecData.userInfo.aadharNumber= decryptAES(objecData.userInfo.aadharNumber)
    objecData.userInfo.userId= decryptAES(objecData.userInfo.userId)
    objecData.userInfo.fullName= decryptAES(objecData.userInfo.fullName)
    return objecData
  }

  const extractMonthFeeData = (data) => {
    let months = [];
    let totalMonthlyFee = 0;
    if(data && data.length>0){
      data.forEach(item => {
        months.push(item.month.charAt(0).toUpperCase() + item.month.slice(1));
        totalMonthlyFee += item.monthlyFee;
      });
      return {
          name: `(${months.join(', ')})`,
          amount: totalMonthlyFee,
          existMonthfee: true
      };
    }else{
      return {
        name: `(${months.join(', ')})`,
        amount: totalMonthlyFee,
        existMonthfee: false
      };
    }

  }
 const extractBusFeeData = (data) => {
    let busMonths = [];
    let totalBusFee = 0;
    if(data && data.length>0){
      data.forEach(item => {
        if (item.busFee > 0) {
          const monthCapitalized = item.month.charAt(0).toUpperCase() + item.month.slice(1);
          busMonths.push(monthCapitalized);
          totalBusFee += item.busFee;
        }
      });
      return {
        name: `(${busMonths.join(', ')})`,
        amount: totalBusFee,
        existBusFee: totalBusFee>0? true: false
      };
    }else{
      return {
        name: `(${busMonths.join(', ')})`,
        amount: totalBusFee,
        existBusFee: false
      }
    }
  }

  export const invoiceFormatData=(invoiceData)=>{
    const monthFeeData= extractMonthFeeData(invoiceData.invoiceInfo.feeList)
    const busFeeData= extractBusFeeData(invoiceData.invoiceInfo.feeList)
    const feeDetails=[]
    
    if(monthFeeData && monthFeeData.existMonthfee){
      feeDetails.push(monthFeeData)
    }
    if(busFeeData && busFeeData.existBusFee){
      feeDetails.push(busFeeData)
    }
    if(invoiceData.invoiceInfo.otherFeeList && invoiceData.invoiceInfo.otherFeeList.length>0){
      feeDetails.push(...invoiceData.invoiceInfo.otherFeeList)
    }
    // Logic for pre-dues and pre-exccess amount calculate
    const monthFee = Number(monthFeeData.amount);
    const busFee = Number(busFeeData.fineAmount);
    const fineAmount = Number(invoiceData.invoiceInfo.fineAmount)||0
    const totalFees = monthFee + busFee +fineAmount;
    const grandTotal = Number(invoiceData.invoiceInfo.totalGrandAmount);

    const hasValidFees = monthFee > 0 || busFee > 0;

    const preDueAmount =invoiceData.invoiceInfo.overDueAmount?invoiceData.invoiceInfo.overDueAmount : (hasValidFees && grandTotal > totalFees )? grandTotal - totalFees : 0;
    console.log('preDueAmount...........>', preDueAmount)
    const preExcessAmount = hasValidFees && grandTotal < totalFees ? totalFees - grandTotal : 0;
    // End of logic

    // const preDueAmount = (Number(monthFeeData.amount) >0 || Number(busFeeData.amount)) && (Number(invoiceData.invoiceInfo.totalGrandAmount) > Number(monthFeeData.amount) + Number(busFeeData.amount)) && (Number(invoiceData.invoiceInfo.totalGrandAmount) - Number(monthFeeData.amount) - Number(busFeeData.amount))
    // const preExcessAmount= (Number(monthFeeData.amount) >0 || Number(busFeeData.amount)) && (Number(invoiceData.invoiceInfo.totalGrandAmount) < Number(monthFeeData.amount) + Number(busFeeData.amount)) && (Number(monthFeeData.amount) + Number(busFeeData.amount)) - Number(invoiceData.invoiceInfo.totalGrandAmount) 
    let pdfInvoiceData={
      invoiceId: invoiceData.invoiceId,
      regId: invoiceData.userId,
      feeDetails:feeDetails,
      stName: invoiceData.invoiceInfo.userData.userInfo.fullName,
      class: invoiceData.invoiceInfo.class,
      session: invoiceData.session,
      payment: invoiceData.invoiceInfo.payment,
      paidAmount: invoiceData.invoiceInfo.paidAmount,
      totalGrandAmount: invoiceData.invoiceInfo.totalAmount,
      receiptNumber:invoiceData.invoiceInfo.receiptNumber? invoiceData.invoiceInfo.receiptNumber:'N/A',
      invoiceType: invoiceData.invoiceType,
      submittedDate: invoiceData.invoiceInfo.submittedDate,
      concession:invoiceData.invoiceInfo.concession? invoiceData.invoiceInfo.concession : 0,
      dueAmount: invoiceData.invoiceInfo.dueAmount ? invoiceData.invoiceInfo.dueAmount : 0,
      excessAmount: invoiceData.invoiceInfo.excessAmount ? invoiceData.invoiceInfo.excessAmount : 0,
      preExcessAmount: preExcessAmount > 0? preExcessAmount : 0,
      preDueAmount: preDueAmount > 0? preDueAmount : 0,
      fineAmount: fineAmount
    }
    console.log("pdfInvoiceData", pdfInvoiceData)
    return pdfInvoiceData
  }
  export const generateSixDigitId=()=> {
      return Math.floor(Math.random() * 900000) + 100000;
  }

  export const sortByRollNumber=(studentArr)=>{
    if(!studentArr) return []
    if(studentArr.length==0) return []
    
    const allRollNumbersValid = (arr) => {
      return arr.every(student => student.hasOwnProperty('rollNumber') && student.rollNumber > 0);
    };
    
    // Function to sort the array by 'rollNumber'
    const sortingByRollNumber = (arr) => {
      return arr.sort((a, b) => a.rollNumber - b.rollNumber);
    };
    
    // First, check if all objects have a valid roll number
    if (allRollNumbersValid(studentArr)) {
     
      // If true, sort the array by roll number
      const sortedStudents = sortingByRollNumber(studentArr);
      // console.log("Sorted Students:", sortedStudents);
      return sortedStudents
    } else {
      console.log("Not all students have a valid roll number.");
      return studentArr.map((data, index)=> {return{...data, rollNumber:index+1}})
    }
  }



export const classList=["1 A","1 B","2 A","2 B","3 A","3 B","4 A","4 B","5 A","5 B","6 A","6 B","7 A","7 B","8 A","9 A","10 A","UKG A","UKG B","LKG A","LKG B","NUR A","NUR B","PRE NUR A", "PRE NUR B"]
export const genderList=["Boy","Girl"]
export const categoryList=["GENERAL","OBC","SC","ST"]
export const filterList =['No Student Photo', 'No St Addhar Front side','No St Addhar Back side', 'No Mobile Number','No Aadhar', 'Deactive', 'Free Students', 'Bus Students' ]
export const roleList=["STUDENT", 'TEACHER']
export const romanNumberList =[{1:'I'},{2:'II'},{3:'III'},{4:'IV'},{5:'V'},{6:'VI'},{7:'VII'},{8:'VIII'},{9:'IX'},{10:'x'}]
export const examList =['UNIT TEST-I', 'UNIT TEST-II', 'HALF YEARLY EXAM', 'ANNUAL EXAM']
export const sessionList =['2022-23', '2023-24', '2024-25', '2025-26']
export const examSubjectList=['MATHS', 'HINDI', 'COMPUTER Comp. Prac.','SCIENCE','SOCIAL SCIENCE','ENGLISH','G.K./M.V.','G.K./M.V. DRAWING','G.K. DRAWING', 'DRAWING','-','COMPUTER', 'COMPUTER  DRAWING','-']
export const subjectList =['HINDI', 'ENGLISH', 'MATH','SCIENCE','SST','COMPUTER','COMP PRACT','HINDI NOTES','ENGLISH NOTES','MATH NOTES','SCIENCE NOTES','SST NOTES','HINDI SUB ENRICH','ENGLISH SUB ENRICH','MATH SUB ENRICH','SCIENCE SUB ENRICH','SST SUB ENRICH','HINDI RHYMES','ENGLISH RHYMES','DRAWING','GK MV','ATTENDANCE']
export const paymentTypeList=['MONTHLY']
//export const paymentTypeList=['MONTHLY','RE-ADMISSION','BOOK','OTHER']
export const monthList =  ["april", "may", "june", "july", "august", "september", "october", "november", "december","january", "february", "march"] 
export const monthListWithValue =  [{name:"april", value:1}, {name:"may", value:2}, {name:"june", value:3}, {name:"july", value:4}, {name:"august", value:5}, {name:"september", value:6}, {name:"october", value:7}, {name:"november", value:8}, {name:"december", value:9},{name:"january", value:10}, {name:"february", value:11}, {name:"march", value:12}] 
export const paymentModeList = ['Cash','Online']
export const paymentRecieverList= ['Vikash','Dinker','Sandeep','Aanand']
export const busList=['bus1','bus2', 'bus3']
export const monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
export const reasonList= ['Third Child','Award/Certificate','By BMMS Trust','Other']
export const otherPaymentTypeList=[{lable:'Tie/Belt (Large)',value:'tieBeltLarge', amount:150},{lable:'Tie/Belt (Small)',value:'tieBeltSmall', amount:100},{lable:'ADMISSION',value:'admission', amount:1500}, {lable:'Book',value:'book', amount:2000},{lable:'School Development(Upper)',value:'reAdmissionUpper', amount:500},{lable:'School Development(Under)',value:'reAdmissionUnder', amount:250}, {lable:'SLC/TC',value:'slc', amount:500}]
export const otherPaymentDuesTypeList=[{lable:'Due Tie/Belt',value:'dueTieBelt', amount:0},{lable:'DUE ADMISSION',value:'dueAdmission', amount:0},{lable:'Due Book', value:'dueBook', amount:0}, {lable:'Due Re-Admission',value:'dueReAdmission', amount:0},{lable:'Due March (Month/Bus)', value:'dueMarch_Month_Bus', amount:0}]